import './homeStyles.css';
import { Row, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault(); 
        setIsLoading(true);

        if (!email.trim() || !name.trim()) {
            alert("Please fill in both the Labcode and your Name 🙌");
            setIsLoading(false);
            return; 
        }

        fetch('https://project-saadhna-certificate-app-y26xkwalgq-uc.a.run.app/requestCertificate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                    "name": name,
                    "email": email
                }),
            })
            .then(response => {
                if (!response.ok) {
                    return response.json().then(err => Promise.reject(err));
                }
                    alert("Please wait a few seconds for the Certificate to be generated 🛠");
                    return response.blob();
                })
            .then(blob => {
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${name}_project_saadhna_certificate.jpeg`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                alert("Certificate Successfully Generated ✅");
                setIsLoading(false);
            })
            .catch(err => {
                console.log('Error message from server:', err.message);
                setIsLoading(false);
                if(err.error){
                    alert("Invalid Email provided ❌");
                }
            });
    }
    return ( 
        <div className="home-wrapper">
            <div className="hero-title-wrapper">
                Certificate Generator 
            </div>
            <div className="hero-subtitle-wrapper">
                Project Saadhna
            </div>
            <div className="info-bar-wrapper">
                Congratulations on Successfully Winning Project Saadhna Cycle 2!
            </div>
            <div className="input-wrapper">
                <Row className="input-row-wrapper">
                    <Col lg={6} md={6} s={6} xs={12}>
                        <div className="details-wrapper">
                            <input type="text" required 
                            value={email} 
                            placeholder="Email" name="" id="" onChange={e=>{setEmail(e.target.value)}}/>
                        </div>
                    </Col>
                    <Col lg={6} md={6} s={6} xs={12}>
                        <div className="details-wrapper">
                            <input type="text" required 
                            value={name} 
                            placeholder="Name" name="" id="" onChange={e=>{setName(e.target.value)}}/>
                        </div>
                    </Col>
                </Row>
                {!isLoading && (
                    <div className="submit-wrapper">
                        <button onClick={(e)=>{handleSubmit(e)}}>Claim Certificate ✨</button>
                    </div>
                )}
                {isLoading && (
                    <div className="submit-wrapper">
                        Generating...
                    </div>
                )}
            </div>
            <Link to="https://codevipassana.dev" style={{ textDecoration: 'none', color: 'white' }} target="_blank" rel="noopener noreferrer">
                <div className="footer-wrapper">
                        Join Code Vipassana
                </div>
            </Link>
        </div>
     );
}
 
export default Home;